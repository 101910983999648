import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      component: () => import("@/views/Home"),
    },
    {
      name: "games",
      path: "/games",
      component: () => import("@/views/Gamelist")
    },
    {
      name: "golfwithyourfriends",
      path: "/golfwithyourfriends",
      component: () => import("@/views/GolfWithYourFriends.vue")
    }
  ]
});