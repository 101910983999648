<template>
  <v-app>
    <Golfball/>
    <v-main>
        <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Golfball from './components/Golfball';

export default {
  name: 'App',

  components: {
    Golfball,
  },

  data: () => ({
    games: [
      {
        name: "Golf with Friends",
        header: "gwfHeader.png",
      },
    ]
  }),
};
</script>

<style>
#app {
  background-image: url('./assets/black_whate_pattern.jpg');
  background-repeat: repeat;
}

h1 {
  color: black;
  font-size:xx-large;
}

</style>
